@import "@/assets/styles/variables.scss";

// para ajustar o tamanho do switch, altere essas 3 variáveis abaixo
$toggle-switch-width: 24px;
$toggle-switch-height: 16px;
$toggle-switch-btn-margin: 2px;

// cores idênticas ao componente `touch-switch` (para phone)
$toggle-switch-bg-on: $primary-color;
$toggle-switch-bg-off: #bfcbd9;
$toggle-switch-btn-size: $toggle-switch-height - (2 * $toggle-switch-btn-margin);
$toggle-switch-btn-color: #fff;

.toggle-switch {
  display: inline-block;
  height: $toggle-switch-height;
}

input[type=checkbox]{
  height: 0;
  width: 0;
  display: none;
  margin: 0;
}

label {
  cursor: pointer;
  width: $toggle-switch-width;
  height: $toggle-switch-height;
  background: $toggle-switch-bg-off;
  display: block;
  border-radius: 150px;
  position: relative;
  margin: 0;

  &:after {
    content: '';
    position: absolute;
    top: $toggle-switch-btn-margin;
    left: $toggle-switch-btn-margin;
    width: $toggle-switch-btn-size;
    height: $toggle-switch-btn-size;
    background: $toggle-switch-btn-color;
    border-radius: 150px;
    transition: 0.3s;
  }

}

input:checked + label {
  background: $toggle-switch-bg-on;
}

input:checked + label:after {
  left: calc(100% - #{$toggle-switch-btn-margin});
  transform: translateX(-100%);
}

input:disabled,
input[readonly] {
  pointer-events: none;

  & + label {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
