@import "@/assets/styles/variables.scss";


.card {
  .template {
    .row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  form {
    margin-bottom: 0;
  }

  h4 {
    .status {
      cursor: help;
      color: gray;

      &.success {
        color: $fern-green;
      }

      &.in_progress {
        color: $sky-blue;
      }

      &.fail {
        color: $pale-red;
      }

      .fa,
      .small {
        margin-left: 10px;
        font-size: 50%;
      }
    }
  }

  .label-fa {
    > .fa {
      margin-left: 10px;
    }
  }
}

.log-row {
  white-space: pre;
  overflow: scroll;
}

.token {
  word-break: break-all;
}

