@import "@/assets/styles/variables.scss";

.list-item {
  cursor: pointer;

  &.read {
    .card {
      background-color: #ecedf4;
    }
  }

  .list-text {
    width: calc(100% - 80px);
  }

  .list-icon {
    width: 70px;
    margin-right: 10px;
    text-align: center;
  }
}

