@import "@/assets/styles/variables.scss";


.classification-line {
  line-height: 38px;
}

.focus {
  background-color: #ffebbd;
  padding: 0 10px;
  transition: 0.2s ease;
}

.error {
  font-size: 12px;
  color: #FF0000;
}

