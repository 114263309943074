@import "@/assets/styles/variables.scss";


.navbar {
  background-color:#006C4C;//, #62B55A);
  color: white;
  line-height: 56px;

  strong {
    float: left;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $pinkish-grey;
    }
  }

  button {
    margin-top: 20px;
    margin-bottom: 0;

    color: $white;
  }
}

.menu {
  list-style: none;
  margin: 0;

  li {
    margin: 0;
    padding: 10px;
    float: left;
  }
}

.notification-badge {
  position: absolute;
  top: -12px;
  right: -15px;
  height: 18px;
  min-width: 18px;
  padding: 0 4px;

  background-color: red;
  border-radius: 18px;
  color: white;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
}

.notification-link {
  position: relative;

  .fa {
    font-size: 1.5em;
  }
}

