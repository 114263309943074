@import "@/assets/styles/variables.scss";











































// TODO definir cor de erro!
$file-error-color: red;
$file-error-border-color: red;
$file-disabled-color: #ABABAB;
$file-disabled-border-color: #D9D6D5;

.file-field {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 4px 4px 6px;
}

.head {
  > .title {
    display: inline-block;
    font-size: 16px;
    margin-right: 12px;
  }

  > .toolbar {
    float: right;

    .button {
      padding: 0 10px;
      line-height: 32px;
      height: 32px;

      + .button {
        margin-left: 6px;
      }

      &.destroy {
        margin-left: 12px;
      }

      .fa {
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
}

.hidden-inputs {
  display: none;
}

.content {
  padding-left: 4px;

  > .fa {
    font-size: 14px;
    margin-right: 4px;
  }

  > .value {
    font-size: 12px;

    &.empty {
      font-style: italic;
    }
  }
}
