@import "@/assets/styles/variables.scss";







/deep/ .switch-field {
  &.disabled,
  &.readonly {
    label.label {
      color: initial;
    }
  }
}
