@import "@/assets/styles/variables.scss";


.chart {
  height: 350px;
}

.card-header {
  padding: 10px 20px;
  margin: -20px -20px 0;
  border-bottom: solid 1px #c4c4c4;
}

.card-body {
  margin-top: 10px;
}

h6 {
  font-size: 18px;
}
