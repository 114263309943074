@import "@/assets/styles/variables.scss";




































.datetimepicker {
  position: relative;

  & > input {
    padding-right: 35px;
    width: 100%;
    cursor: pointer;
  }

  & > .fa {
    font-size: 16px;
    color: #777;

    position: absolute;
    top: 0;
    right: 0;

    // XXX essas são as medidas do input no SkeletonCSS
    // height: 38px;
    // padding: 6px 10px;
    // -> então, calculamos o padding para alinhar o ícone:
    padding: 11px;

    pointer-events: none;
  }
}

