@import "@/assets/styles/variables.scss";








































































.component {
  text-align: center;
}

button {
  background: #fff;
}

button + button {
  margin-left: 8px;
}

button[disabled] {
  opacity: 0.5;
}

.btn-first > .fa,
.btn-prev > .fa {
  margin-right: 4px;
}

.btn-next > .fa,
.btn-last > .fa {
  margin-left: 4px;
}

.page {
  border: 0;
  font-size: 16px;
}

