@import "@/assets/styles/variables.scss";


















.numeric-field {
  margin-bottom: 1.5rem;
  /deep/ .input-field {
    margin-bottom: 0;
  }
}

.input-field {
  width: 100%;
}

