@import "@/assets/styles/variables.scss";




















































.sort-link {
  text-decoration: none;

  > .fa {
    margin-left: 4px;
  }
}

