@import "@/assets/styles/variables.scss";


thead {
  background-color: $brownish-grey;
  color: $white;

  a {
    color: $white;
  }
}

h6 {
  font-size: 18px;
  font-weight: 700;
  color: $greyish-brown-two;
}

.price-total {
  font-size: 20px;
  letter-spacing: 0.3px;
}

.price-container {
  padding: 10px;

  &.refused,
  &.coop_refused {
    background-color: rgba(255, 95, 64, 0.05);
    border: solid 1px #ff5f40;
  }

  &.accepted,
  &.coop_accepted {
    background-color: rgba(126, 211, 33, 0.05);
    border: solid 1px #6ba62a;
  }
}

