@import "@/assets/styles/variables.scss";






























































.overlay {
  .fa {
    display: none;
  }
}

.loading-map,
.map-container {
  position: relative;
}

.leaflet-dragging {
  .overlay {
    height: 100%;
    width: 100%;
    z-index: 2000;
    position: absolute;
    background-color: rgba(47, 47, 47, 0.6);
    text-align: center;
    line-height: 380px;

    .fa {
      display: inline-block;
      color: $white;
    }
  }
}

.loading-map {
  .overlay {
    height: 100%;
    width: 100%;
    z-index: 10000;
    position: absolute;
    background-color: rgba(47, 47, 47, 0.6);
    text-align: center;
    line-height: 380px;

    .fa {
      display: inline-block;
      color: $white;
    }
  }
}

