@import "@/assets/styles/variables.scss";



























@import "overlay-wnd-variables";

.overlay.level-1,
.overlay.level-1 > .content {
  z-index: $overlay-z-index-level-1;
}

.overlay.level-1 > .content > .overlay-btn-close {
  z-index: $overlay-z-index-level-1 + 1;
}

.overlay.level-2,
.overlay.level-2 > .content {
  z-index: $overlay-z-index-level-2;
}

.overlay.level-2 > .content > .overlay-btn-close {
  z-index: $overlay-z-index-level-2 + 1;
}

.overlay.level-3,
.overlay.level-3 > .content {
  z-index: $overlay-z-index-level-3;
}

.overlay.level-3 > .content > .overlay-btn-close {
  z-index: $overlay-z-index-level-3 + 1;
}

.overlay {
  position: fixed;
  top: 0; bottom: 0; right: 0; left: 0;
  background-color: #fff;

  & > .content > .overlay-btn-close {
    height: auto;
    line-height: auto;

    border: 0;
    outline: 0;

    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    margin: 0;

    color: #aaa;
  }

  & > .content {
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: relative;
  }
}


/* NOTE reaproveitando transições globais de `applcation/_transitions.scss` */

