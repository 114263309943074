.button.button {
  background-color: white;
}

.button.button.button-primary,
button.button-primary.button-primary {
  background-color:#006C4C;
  border-color: #006C4C;
}

.button {
  &.button-submit {
  color: $white;
  border-color: rgb(46, 158, 136);
  background-color: rgb(46, 158, 136);
  }

  &.button-destroy {
    border-color: $danger-color;
  }
}

.button-success,
.button-submit {
  color: $white;
  border-color: #62B55A;
  background-color: #62B55A !important;

  &:focus,
  &:hover,
  &:active {
    color: $white;
  }
}

.button-danger {
  background-color: $danger-color !important;
  border-color: darken($danger-color, 15%);
  color: white !important;
}

.button-icon {
  padding: 0 15px;
  line-height: 42px;
}
