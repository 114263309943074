@import "@/assets/styles/variables.scss";


h4 {
  font-size: 20px;
  letter-spacing: 0.3px;
  color: $greyish-brown-two;
}

.row {
  &.error {
    color: red;
  }
}

tr {
  &.focus {
    padding: 15px;
    background-color: rgba(255, 255, 0, 0.3);

  }

  td {
    &:last-child {
      padding-right: 15px;
    }

    .fa {
      cursor: pointer;
    }
  }
}
