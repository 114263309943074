@import "@/assets/styles/variables.scss";


.badge {
  &.draft,
  &.closed,
  &.waiting {
    background-color: $greyish-brown;
  }

  &.ongoing {
    background-color: $primary-color;
  }

  &.canceled,
  &.failure {
    background-color: $danger-color;
  }

  &.suspended,
  &.under_review {
    background-color: $peach;
  }

  &.approved,
  &.finnished,
  &.accepted {
    background-color: $apple-green;
  }
}

.bidding-description {
  min-height: 50px;
}

.cooperative-name {
  min-height: 70px;
}

