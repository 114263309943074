@import "@/assets/styles/variables.scss";




































// TODO definir cor de erro!
$input-error-color: red;
$input-error-border-color: red;
$input-disabled-color: #CCC;
$input-disabled-border-color: #E3E3E3;

.textarea-field[required="required"],
.input-field[required="required"],
.field[required="required"] {
  label {
    &:after {
      content:"*"
    }
  }
}

.input-field {
  margin-bottom: 1.5rem;

  input {
    width: 100%;
    // Sobrecarregando estilo do Skeleton, trocando a margem para o "container",
    // fazendo com que as mensagens de erro fiquem próximas ao input (antes da
    // margem)
    // margin-bottom: 1.5rem;
    margin-bottom: 0;
  }
}

// error
.input-field.error {
  label {
    color: $input-error-color;
  }

  input {
    border-color: $input-error-border-color;
  }
}

// disabled
.input-field.disabled,
.input-field.readonly {
  label {
    color: $input-disabled-color;
  }

  input {
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
  }
}
