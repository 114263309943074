@import "@/assets/styles/variables.scss";


































// TODO definir cor de erro!
$textarea-error-color: red;
$textarea-error-border-color: red;
$textarea-disabled-color: #ABABAB;
$textarea-disabled-border-color: #D9D6D5;

.textarea-field[required="required"] {
  label {
    &:after {
      content:"*"
    }
  }
}

.textarea-field {
  margin-bottom: 1.5rem;

  textarea {
    width: 100%;
    height: inherit;
    // Sobrecarregando estilo do Skeleton, trocando a margem para o "container",
    // fazendo com que as mensagens de erro fiquem próximas ao input (antes da
    // margem)
    // margin-bottom: 1.5rem;
    margin-bottom: 0;

    resize: none;
  }
}

// error
.textarea-field.error {
  label {
    color: $textarea-error-color;
  }

  textarea {
    border-color: $textarea-error-border-color;
  }
}

// disabled
.textarea-field.disabled {
  label {
    color: $textarea-disabled-color;
  }

  textarea {
    border-color: $textarea-disabled-border-color;
  }
}

textarea.resize {
  resize: both;
}
