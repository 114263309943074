@import "@/assets/styles/variables.scss";

.dashboard-container {
  margin-top: 20px;

  h2 {
    font-size: 20px;
    letter-spacing: 0.3px;
    color: $greyish-brown-two;
  }
}

.card-icon {
  color: $white;
  text-align: center;
  height: 90px;
  line-height: 86px;
  border-radius: 2px;
}

.card-text-title {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: $greyish-brown-two;
}

.card {
  &.blue {
    .card-icon {
      background-color: $sky-blue;
    }
  }

  &.green {
    .card-icon {
      background-color: $turquoise-blue;
    }
  }

  &.orange {
    .card-icon {
      background-color: $peach;
    }
  }
}

