@import "@/assets/styles/variables.scss";

.togglable-div-btn {
  text-align: right;
  display: block;
  color: #000;
  cursor: pointer;
  padding: 8px 0;
  margin-top: 4px;
}

.content {
  // para não depender do tamanho dos filhos!
  overflow: hidden;
}

.toggle-slidding-enter-active,
.toggle-slidding-leave-active {
  transition: all .3s ease;
}

.toggle-slidding-enter,
.toggle-slidding-leave-to {
  // aqui deixamos as propriedades que queremos animadas
  height: 0;
  opacity: 0;
}

