@import "@/assets/styles/variables.scss";

















@import "modal-wnd-variables";

.modal-mask {
  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(218, 218, 218, 0.7);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  z-index: $modal-z-index + 1;
}

.modal-container {
  position: relative;

  /*
    TODO definir largura do modal. Entender como reusar componente tanto
    para desktop quanto para phone.
    ex: width: 300px;
  */
  width: 94%;
  max-width: 900px;

  // Altura total menos margin
  max-height: calc(100vh - 40px);
  margin: 20px auto;
  overflow: scroll;

  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  /*font-family: Helvetica, Arial, sans-serif;*/
}

.modal-close-btn {
  z-index: $modal-z-index + 2;

  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 20px;

  color: #C9C9C9;
}

.modal-header {
  color: $primary-color;
  margin-top: 0;
  margin-bottom: 20px;
}

.modal-header > h1 {
  font-size: 2em;
}

.modal-body {
}

.modal-footer {
  margin-top: 20px;
}

.modal-default-button {
  float: right;
}

.button-success {
  color: $white;
  background-color: $dark-grey-blue !important;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
