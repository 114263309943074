@import "@/assets/styles/variables.scss";





















































html,
body {
 background-color: $pale-grey;
}
