@import "@/assets/styles/variables.scss";






























// TODO definir cor de erro!
$label-error-color: red;

.label-field {
  margin-bottom: 1.5rem;
}

// error
.input-field.error {
  label {
    color: $label-error-color;
  }

  span.value {
    color: $label-error-color;
  }
}

.empty {
  font-size: 12px;
  font-style: italic;
  color: #cfcfcf;
}
