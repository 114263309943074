/**
 * -----------------
 *  Vue transitions
 * -----------------
 */

/**
 * slide-up transition
 */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all .3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-up-fading-enter-active,
.slide-up-fading-leave-active {
  transition: all .3s ease;
}

.slide-up-fading-enter,
.slide-up-fading-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

/**
 * slide-down transition
 */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all .3s ease;
}

.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(-100%);
}

.slide-down-fading-enter-active,
.slide-down-fading-leave-active {
  transition: all .3s ease;
}

.slide-down-fading-enter,
.slide-down-fading-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

/**
 * slide-left transition
 */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .3s ease;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(100%);
}

.slide-left-fading-enter-active,
.slide-left-fading-leave-active {
  transition: all .3s ease;
}

.slide-left-fading-enter,
.slide-left-fading-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/**
 * fade transition
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}
