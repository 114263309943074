@import "@/assets/styles/variables.scss";



































// XXX sem scoped por causa do select2!

// TODO definir cor de erro!
$select-error-color: red;
$select-error-border-color: red;
$select-disabled-color: #ABABAB;
$select-disabled-border-color: #D9D6D5;

.select-field {
  margin-bottom: 1.5rem;

  .select2.select2-container--default {
    width: 100%;
    // Sobrecarregando estilo do Skeleton, trocando a margem para o "container",
    // fazendo com que as mensagens de erro fiquem próximas ao input (antes da
    // margem)
    // margin-bottom: 1.5rem;
    margin-bottom: 0;
  }
}

// error
.select-field.error {
  & > label {
    color: $select-error-color;
  }

  .select2.select2-container--default {
    border-color: $select-error-border-color;
  }
}

// disabled
.select-field.disabled {
  & > label {
    color: $select-disabled-color;
  }

  .select2.select2-container--default {
    border-color: $select-disabled-border-color;
  }
}
