//
// Classes utilitárias comuns a toda a aplicação
//

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.no-scroll {
  overflow: hidden;
}

.preserve-whitespace {
  white-space: pre-wrap;
}
