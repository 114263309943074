@import "@/assets/styles/variables.scss";

$touch-switch-core-size: 34px;
$touch-switch-height: 38px;
$touch-switch-width: 50px;
$touch-switch-small-width: 80px;
$touch-switch-medium-width: 100px;
$touch-switch-large-width: 120px;

.touch-switch {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  line-height: $touch-switch-height;
  height: $touch-switch-height;

  .input {
    display: none;
  }

  .label {
    position: absolute;
    top: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: $touch-switch-height;
    height: $touch-switch-height;
    color: white;

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }

  .core,
  .core.none {
    margin: 0;
    display: inline-block;
    position: relative;
    outline: 0;
    border-radius: 25px;
    box-sizing: border-box;
    background: #bfcbd9;
    transition: border-color .3s, background-color .3s;
    width: $touch-switch-width;
    height: $touch-switch-height;

    &.small {
      width: $touch-switch-small-width;
    }

    &.medium {
      width: $touch-switch-medium-width;
    }

    &.large {
      width: $touch-switch-large-width;
    }

    &:before {
      display: block;
      content: '';
      overflow: hidden;
      transform: translate(2px, 2px);
      top: 2px;
      left: 1px;
      position: absolute;
      border-radius: 100%;
      transition: transform .3s;
      width: 30px;
      height: 30px;
      z-index: 20;
      background-color: #fff;
    }
  }

  &.checked {
    .core,
    .core.none {
      &:before {
        transform: translate(30px, 2px);
        transform: translate(calc(#{$touch-switch-width} - #{$touch-switch-core-size}), 2px);
      }
    }
    .core.small {
      &:before {
        transform: translate(50px, 2px);
        transform: translate(calc(#{$touch-switch-small-width} - #{$touch-switch-core-size}), 2px);
      }
    }
    .core.medium {
      &:before {
        transform: translate(70px, 2px);
        transform: translate(calc(#{$touch-switch-medium-width} - #{$touch-switch-core-size}), 2px);
      }
    }
    .core.large {
      &:before {
        transform: translate(90px, 2px);
        transform: translate(calc(#{$touch-switch-large-width} - #{$touch-switch-core-size}), 2px);
      }
    }
  }

  &.disabled,
  &.readonly {
    cursor: not-allowed;
    opacity: 0.5;

    & > input[type="checkbox"] {
      pointer-events: none;
    }
  }
}
