@import "@/assets/styles/variables.scss";


.header {
  padding: 20px 0;

  background-color: white;
  box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 30px;
}

