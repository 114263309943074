@import "@/assets/styles/variables.scss";


.title {
  margin-top: 20px;
}

.cooperative {
  > .row {
    margin-bottom: 10px;
  }
}

h4 {
  font-size: 20px;
  letter-spacing: 0.3px;
  color: $greyish-brown-two;
}
