@import "@/assets/styles/variables.scss";


.card {
  min-height: 220px;
  position: relative;
}

.card-header {
  padding: 10px 20px;
  margin: -20px -20px 0;
  border-bottom: solid 1px #c4c4c4;
}

.card-body {
  margin-top: 10px;
}

.card-footer {
  padding-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  position: absolute;
  bottom: 0;
}

h6 {
  font-size: 18px;
}
