@import "@/assets/styles/variables.scss";


.chart {
  height: 350px;
}

.card-header {
  padding: 10px 20px;
  margin: -20px -20px 0;
  border-bottom: solid 1px #c4c4c4;

  &.draft,
  &.closed,
  &.draw,
  &.waiting {
    color: $greyish-brown;
  }

  &.ongoing {
    color: $primary-color;
  }

  &.canceled,
  &.failure {
    color: $danger-color;
  }

  &.suspended,
  &.under_review {
    color: $peach;
  }

  &.approved,
  &.finnished,
  &.accepted {
    color: $apple-green;
  }
}

.card-body {
  margin-top: 10px;
}

h6 {
  font-size: 18px;
}
