@import "@/assets/styles/variables.scss";
$primary-color: #006C4C;
$secondary-color: #62B55A;

@import "lib/lib.scss";
@import "assets/styles/skeleton_overrides";
@import "assets/styles/vuejsdialog_overrides";
@import "~skeleton-scss/scss/skeleton"; // import skeleton, with normalize already included
@import "assets/styles/base/base";

// SAMPLE:
// @import "styles/overrides";
// @import "styles/transitions";
// @import "styles/utils";

* {
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
