@import "@/assets/styles/variables.scss";


thead {
  background-color: $brownish-grey;
  color: $white;

  a {
    color: $white;
  }
}

.badge {
  &.draft {
    background-color: $greyish-brown;
  }

  &.canceled,
  &.failure {
    background-color: $danger-color;
  }

  &.accepted {
    background-color: $apple-green;
  }

  &.desert {
    background-color: $peach;
  }
}

ul {
  list-style-type: none;
  list-style: none;
}


