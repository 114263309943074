@import "@/assets/styles/variables.scss";

table {
  td,
  th {
    padding: 20px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  tbody {
    background-color: $white;
  }
}
