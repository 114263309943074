@import "@/assets/styles/variables.scss";

.content {
  padding: 12px;
  color: $metallic-blue;
}

.logo-container {
  font-size: 20px;
  margin-bottom: 20px;
}

.logo {
  max-height: 100px;
  margin-top: 50px;
}

input {
  border: solid 1px $dark-grey-blue !important;
}

.button {
  &.button-primary {
    background-color: $dark-grey-blue !important;
  }
}

.password-container {
  padding: 0 30px;
}

