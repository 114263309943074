@import "@/assets/styles/variables.scss";

.filter-bar {
  padding: 10px 20px;

  &.default {
    background-color: $greyish-brown;
  }

  &.custom {
    padding-left: 0;
    padding-right: 0;
  }

  form,
  .input-field,
  button {
    margin-bottom: 0;
  }
}

.search-field {
  position: relative;

  > .fa {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 12px;
  }
}
