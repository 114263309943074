//
// desligando botão de "autocomplete" de inputs `type="email"` ou `type="tel"`, pois eles
// possuem ícone próprio (na UI) e o botão extra do Safari torna o input muito pequeno!
//
// @see https://gist.github.com/jeroenvisser101/9254cf27df85cf1c853aee17e4b49766
input[autocomplete="off"]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  height: 0;
  width: 0;
  margin: 0;
}

// properly override
#view {
  .button.button-primary:hover,
  button.button-primary:hover {
    color: #fff;
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }
}
