@import "@/assets/styles/variables.scss";

.avatar {
  display: block;
  margin-left: auto;
  margin-right: auto;

  height: 60px;
  width: 60px;
  border-radius: 100%;
  border: solid 1px #dadada;
}

.avatar-loading {
  background-color: #dadada;

  i {
    padding: 23px;
  }
}

.input-file {
  display: none;
}
