@import "@/assets/styles/variables.scss";































// TODO definir cor de erro!
$switch-error-color: red;
// $switch-error-border-color: red;
$switch-disabled-color: #ABABAB;
// $switch-disabled-border-color: #D9D6D5;

.switch-field {
  margin-bottom: 10px; //1.5rem => era 15px;

  label {
    font-size: 16px; // tamanho do "texto" em inputs

    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    margin: 0;
  }

  .toggle-switch {
    margin-right: 8px;
    vertical-align: middle;
  }
}

// error
.switch-field.error {
  label {
    color: $switch-error-color;
  }

  .toggle-switch {
    // border-color: $switch-error-border-color;
  }
}

// disabled
.switch-field.disabled,
.switch-field.readonly {
  label {
    color: $switch-disabled-color;
  }

  .toggle-switch {
    // border-color: $switch-disabled-border-color;
  }
}
