@import "@/assets/styles/variables.scss";


 .button-fail {
   position: absolute;
   right: 20px;
   bottom: 0;
 }

.badge {
   &.draft,
   &.closed,
   &.waiting {
     background-color: $greyish-brown;
   }

   &.ongoing {
     background-color: $primary-color;
   }

   &.canceled,
   &.failure {
     background-color: $danger-color;
   }

   &.suspended,
   &.under_review {
     background-color: $peach;
   }

   &.approved,
   &.finnished,
   &.accepted {
     background-color: $apple-green;
   }
 }
