@import "@/assets/styles/variables.scss";































.error {
  display: block;
  font-size: 12px;
  color: #FF0000;
}
