$apple-green: #7ed321;
$black: #000000;
$brownish-grey: #666666;
$cerulean: #0275d8;
$charcoal-grey: #2f373f;
$dark-grey-blue: #3a596d;
$dark-grey: #212529;
$dark-sky-blue: #4a90e2;
$duck-egg-blue: #d9edf7;
$fern-green: #3c763d;
$greyish-brown-two: #4a4a4a;
$greyish-brown: #4d4d4d;
$light-blue-grey-two: #d4ede8;
$light-blue-grey: #ddecf7;
$light-grey-blue: #8bb6d1;
$light-grey-green-two: #d0e9c6;
$light-grey-green: #dff0d8;
$metallic-blue: #496c80;
$pale-grey-two: #f8f9fa;
$pale-grey: #f1f2f7;
$pale-red: #d9534f;
$pale-teal: #86beb3;
$peach: #ffaf7a;
$pinkish-grey: #cfcfcf;
$primary-color: $cerulean;
$sky-blue: #5ba9f6;
$turquoise-blue: #00c1d1;
$ugly-blue: #31708f;
$white-four: #d8d8d8;
$white-three: #f1f1f1;
$white-two: #dfdfdf;
$white: #ffffff;

$primary-color: #006C4C;
$danger-color: $pale-red;


// export colors to use @ js - import variables from '@/assets/styles/variables.scss';
:export {
  black: $black;
  greyishBrown: $greyish-brown;
  brownishGrey: $brownish-grey;
  cerulean: $cerulean;
  lightGreyGreen: $light-grey-green;
  lightGreyGreenTwo: $light-grey-green-two;
  pinkishGrey: $pinkish-grey;
  greyishGrownTwo: $greyish-brown-two;
  white: $white;
  whiteTwo: $white-two;
  fernGreen: $fern-green;
  paleGrey: $pale-grey;
  charcoalGrey: $charcoal-grey;
  skyBlue: $sky-blue;
  turquoiseBlue: $turquoise-blue;
  peach: $peach;
  paleRed: $pale-red;
  darkGrey: $dark-grey;
  whiteThree: $white-three;
  paleGreyTwo: $pale-grey-two;
  darkSkyBlue: $dark-sky-blue;
  whiteFour: $white-four;
  appleGreen: $apple-green;
  uglyBlue: $ugly-blue;
}
