@import "@/assets/styles/variables.scss";

.badge {
  &.waiting_signature {
    background-color: $greyish-brown;
  }

  &.signed {
    background-color: $primary-color;
  }

  &.refused {
    background-color: $danger-color;
  }

  &.partial_execution,
  &.total_inexecution {
    background-color: $peach;
  }

  &.completed {
    background-color: $apple-green;
  }
}
