@import "@/assets/styles/variables.scss";


.title {
  margin-top: 20px;
}

.group {
  .row {
    margin-bottom: 10px;
  }
}

